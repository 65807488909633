<template>
    <div class="main-box">
        <div class="pd-box" v-if="sList.length != 0">
            <div style="display: flex;height: 35px;line-height: 35px;margin-bottom: 20px;" v-for="(item, i) in sList" :key="i">
                <div v-if="item.READ_STATUS === '未读'" class="info-status-new">未读</div>
                <div v-else class="info-status-old">已读</div>
                <div style="width: calc(100% - 300px);border-bottom: 1px solid #eeeeee;text-indent: 10px;cursor: pointer;" @click="$router.push({ path: '/PersonalLayout/MessageDetail', query: { id: item.SYSMESSAGE_ID } })">{{item.MESSAGE}}</div>
                <div style="width: 100px;text-align: center;border-bottom: 1px solid #eeeeee;cursor: pointer;">{{item.CTIME}}</div>
                <div style="width: 150px;text-align: center;">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDelete(item.SYSMESSAGE_ID)"
                        @cancel="cancel"
                    >
                        <a-button type="primary">删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
        </div>
        <div class="pd-box" style="padding: 50px 0;text-align: center;" v-else>
            <img src="../assets/images/no-data.png" alt="">
        </div>
        <div class="page-box" v-if="sList.length != 0">
            <a-pagination show-quick-jumper v-model:current="page" :total="total" @change="onPage" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    computed: {
        ...mapGetters(['orgId'])
    },
    data () {
        return {
            sList: [],
            page: 1,
            total: 0
        }
    },
    methods: {
        // 删除单条消息
        onDelete (sid) {
            this.$store.dispatch('deleteSysMessageItem', { SYSMESSAGE_ID: sid, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getSysMessageList()
                } else {
                    this.$message.error('系统异常，删除失败，请稍后重试！')
                }
            })
        },
        // 翻页
        onPage (pageNumber) {
            this.page = pageNumber
            this.getSysMessageList()
        },
        // 获取消息列表
        getSysMessageList () {
            this.$store.dispatch('getSysMessageList', {
                showCount: 10,
                currentPage: this.page,
                ORG_ID: this.orgId,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            datas[i].CTIME = datas[i].CTIME.split('T')[0]
                        }
                        this.sList = datas
                        this.total = res.page.totalResult
                    } else {
                        this.sList = []
                        this.total = 0
                        this.page = 1
                    }
                } else {
                    this.sList = []
                    this.total = 0
                    this.page = 1
                    this.$message.error('系统异常，获取系统消息列表失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        // 获取消息列表
        this.getSysMessageList()
    }
}
</script>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        min-height: 100px;
        .info-status-new {
            color: #ffffff;
            width: 50px;
            text-align: center;
            background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
            background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
            background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
            background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
        }
        .info-status-old {
            color: #ffffff;
            width: 50px;
            text-align: center;
            background: #eeeeee;
        }
    }

    .pd-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }

    .page-box{
        padding: 15px 20px;
        background-color: @color-ff;
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
        .ant-pagination{
            display: flex;
            justify-content: center;
            
        }
    }
    .page-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 11px;
        width: 6px;
        height: 40px;
    }
    .page-box::after{
        content: '';
        position: absolute;
        right: -3px;
        top: 11px;
        width: 6px;
        height: 40px;
    }
}
</style>